import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";

export const locatePhoneNumberIPQS = createAsyncThunk(
  "locatePhoneNumberIPQS",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/locate_phone_info/ipqs`,
        data
      );
      return response;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const locatePhoneNumberIPQSlice = createSlice({
  name: "locatePhoneNumberIPQS",
  initialState: {
    isLoading: false,
    result: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
    .addCase(locatePhoneNumberIPQS.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(locatePhoneNumberIPQS.fulfilled, (state, action) => {
      state.isLoading = false;
      state.result = action.payload;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(locatePhoneNumberIPQS.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  },
});

export default locatePhoneNumberIPQSlice.reducer;
