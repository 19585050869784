import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const getSendDetails = createAsyncThunk(
  "getSendDetails",
  async (data) => {
    try {
      const response = await AxiosInstance.get(`/setting/send-details/${data}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const getSendDetailsSlice = createSlice({
  name: "getSendDetails",
  initialState: {
    isLoading: false,
    getSendDetailsData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
    .addCase(getSendDetails.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSendDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getSendDetailsData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(getSendDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  },
});

export default getSendDetailsSlice.reducer;
