import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const validLocationKey = createAsyncThunk(
  "validLocationKey",
  async (id) => {
    try {
      const response = await AxiosInstance.get(`/location/valid/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);
const validLocationKeySlice = createSlice({
  name: "validLocationKey",
  initialState: {
    isLoading: false,
    validLocationKeyData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(validLocationKey.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validLocationKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.validLocationKeyData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(validLocationKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },  
});

export default validLocationKeySlice.reducer;
