import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const trailSubscription = createAsyncThunk(
  "trailSubscription",
  async (data) => {
    try {
      const response = await AxiosInstance.post(`/subscription/trial`, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const trailSubscriptionSlice = createSlice({
  name: "trailSubscription",
  initialState: {
    isLoading: false,
    trailSubscriptionData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(trailSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(trailSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.trailSubscriptionData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(trailSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default trailSubscriptionSlice.reducer;
