import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const resetPassword = createAsyncThunk("resetPassword", async (data) => {
  try {
    const response = await AxiosInstance.post(`/user/reset-password`, {
      email: data,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    isLoading: false,
    resetPassword: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resetPassword = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default resetPasswordSlice.reducer;
