import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";

export const adminUsers = createAsyncThunk(
  "adminUsers",
  async (_, { signal }) => {
    const source = AxiosInstance.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await AxiosInstance.get(`/user/admin_user/list`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (AxiosInstance.isCancel(error)) {
      } else if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const adminUsersSlice = createSlice({
  name: "adminUsers",
  initialState: {
    isLoading: false,
    subscriptionData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
    .addCase(adminUsers.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(
    adminUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(
    adminUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  },
});

export default adminUsersSlice.reducer;
