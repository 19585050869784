import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getIPIfy = createAsyncThunk("getIPIfy", async () => {
  try {
    // First API call (ipify)
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data;
  } catch (error) {
    // If ipify fails, fall back to jsonip
    console.error("ipify failed, falling back to jsonip:", error);

    try {
      const response = await axios.get("https://ipv4.jsonip.com/");
      return response.data;
    } catch (fallbackError) {
      // If both requests fail, throw an error
      console.error("Both ipify and jsonip failed:", fallbackError);
      throw fallbackError.response ? fallbackError.response.data.message : fallbackError;
    }
  }
});

const getIPIfySlice = createSlice({
  name: "getIPIfy",
  initialState: {
    isLoading: false,
    getIPIfyData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
    .addCase(getIPIfy.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getIPIfy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getIPIfyData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(getIPIfy.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  },
});

export default getIPIfySlice.reducer;
