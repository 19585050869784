import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const gclidCheck = createAsyncThunk("gclidCheck", async (data) => {
  try {
    const response = await AxiosInstance.post(`/setting/upsert`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const gclidCheckSlice = createSlice({
  name: "gclidCheck",
  initialState: {
    isLoading: false,
    gclidCheckData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
    .addCase(gclidCheck.pending, (state) => {
      state.isLoading = true;
  })
    .addCase(gclidCheck.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gclidCheckData = action.payload;
      state.isError = false;
      state.errorMessage = "";
  })
    .addCase(gclidCheck.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
  })
  },
});

export default gclidCheckSlice.reducer;
