import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const smsConfig = createAsyncThunk("SmsServices", async (data) => {
  try {
    const response = await AxiosInstance.post(
      `/setting/update-sms-config`,
      data
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const SmsServicesSlice = createSlice({
  name: "smsConfig",
  initialState: {
    isLoading: false,
    emailSenderData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(smsConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(smsConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailSender = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(smsConfig.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default SmsServicesSlice.reducer;
