import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";

export const adminDeleteUser = createAsyncThunk(
  "adminDeleteUser",
  async (id) => {
    try {
      const response = await AxiosInstance.delete(`/user/delete-user/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const adminDeleteUserSlice = createSlice({
  name: "adminDeleteUser",
  initialState: {
    isLoading: false,
    adminDeleteUserData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminDeleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(adminDeleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.adminDeleteUserData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(adminDeleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },  
});

export default adminDeleteUserSlice.reducer;
