import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const paymentMethod = createAsyncThunk(
  "payment-method-create",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/payment-method-create`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const paymentMethodSlice = createSlice({
  name: "payment-method-create",
  initialState: {
    isLoading: false,
    paymentMethodData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(paymentMethod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paymentMethod.fulfilled, (state, action) => {
        state.isLoading = false;
        state.paymentMethodData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(paymentMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default paymentMethodSlice.reducer;
