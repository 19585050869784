import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const checkSettingBanIp = createAsyncThunk(
  "checkSettingBanIp",
  async (data) => {
    try {
      const response = await AxiosInstance.post(`/setting/status`, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const checkSettingBanIpSlice = createSlice({
  name: "checkSettingBanIp",
  initialState: {
    isLoading: false,
    checkSettingBanIpData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkSettingBanIp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkSettingBanIp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkSettingBanIpData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(checkSettingBanIp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default checkSettingBanIpSlice.reducer;
